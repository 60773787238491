// Colors
$white: var(--chakra-colors-white);
$black: var(--chakra-colors-black);

$brand: (
    main: var(--chakra-colors-brand-50),
    50: var(--chakra-colors-brand-50),
    100: var(--chakra-colors-brand-100),
    200: var(--chakra-colors-brand-200),
    300: var(--chakra-colors-brand-300),
    400: var(--chakra-colors-brand-400),
    500: var(--chakra-colors-brand-500),
    600: var(--chakra-colors-brand-600),
    700: var(--chakra-colors-brand-700),
    800: var(--chakra-colors-brand-800),
    900: var(--chakra-colors-brand-900),
    950: var(--chakra-colors-brand-950)
);

$accent: (
    main: var(--chakra-colors-accent-500),
    border: var(--chakra-colors-accent-200),
    50: var(--chakra-colors-accent-50),
    100: var(--chakra-colors-accent-100),
    200: var(--chakra-colors-accent-200),
    300: var(--chakra-colors-accent-300),
    400: var(--chakra-colors-accent-400),
    500: var(--chakra-colors-accent-500),
    600: var(--chakra-colors-accent-600),
    700: var(--chakra-colors-accent-700),
    800: var(--chakra-colors-accent-800),
    900: var(--chakra-colors-accent-900),
    950: var(--chakra-colors-accent-950)
);

$danger: (
    50: var(--chakra-colors-danger-50),
    100: var(--chakra-colors-danger-100),
    200: var(--chakra-colors-danger-200),
    300: var(--chakra-colors-danger-300),
    400: var(--chakra-colors-danger-400),
    500: var(--chakra-colors-danger-500),
    600: var(--chakra-colors-danger-600),
    700: var(--chakra-colors-danger-700),
    800: var(--chakra-colors-danger-800),
    900: var(--chakra-colors-danger-900),
    950: var(--chakra-colors-danger-950)
);

$yellow: (
    main: var(--chakra-colors-yellow-100),
    100: var(--chakra-colors-yellow-100),
    400: var(--chakra-colors-yellow-400)
);

$green: (
    50: var(--chakra-colors-green-50),
    100: var(--chakra-colors-green-100),
    200: var(--chakra-colors-green-200),
    300: var(--chakra-colors-green-300),
    400: var(--chakra-colors-green-400),
    500: var(--chakra-colors-green-500),
    600: var(--chakra-colors-green-600),
    700: var(--chakra-colors-green-700),
    800: var(--chakra-colors-green-800),
    900: var(--chakra-colors-green-900),
    950: var(--chakra-colors-green-950)
);

$grey: (
    main: var(--chakra-colors-grey-500),
    100: var(--chakra-colors-grey-100),
    200: var(--chakra-colors-grey-200),
    300: var(--chakra-colors-grey-300),
    400: var(--chakra-colors-grey-400),
    500: var(--chakra-colors-grey-500),
    600: var(--chakra-colors-grey-600),
    700: var(--chakra-colors-grey-700),
    800: var(--chakra-colors-grey-800),
    900: var(--chakra-colors-grey-900),
    950: var(--chakra-colors-grey-950)
);

$orange: (
    main: var(--chakra-colors-orange-main)
);

$user: (
    main: var(--chakra-colors-user-main),
    none: var(--chakra-colors-user-none),
    lame: var(--chakra-colors-user-lame),
    leader: var(--chakra-colors-user-leader),
    transparent: var(--chakra-colors-user-transparent)
);

$myProgress: (
    gold: (
        dark: var(--chakra-colors-myProgress-gold-dark),
        light: var(--chakra-colors-myProgress-gold-light)
    ),
    grey: (
        light: var(--chakra-colors-myProgress-grey-light),
        dark: var(--chakra-colors-myProgress-grey-dark)
    )
);
