.navigation {
    nav {
        ul {
            column-gap: 0;
            @media screen and (min-width: 90rem) {
                column-gap: 0.5rem;
            }
        }
        a {
            gap: 0 !important;
            svg {
                &:first-child {
                    margin-right: 3px;
                    @media screen and (min-width: 90rem) {
                        margin-right: 4px;
                    }
                }
                &:last-child {
                    margin-left: 3px;
                    @media screen and (min-width: 90rem) {
                        margin-left: 4px;
                    }
                }
            }
        }
        button {
            margin-right: 1rem;

            span:first-child {
                margin-right: 0;
            }
        }
    }
}
