* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

::selection {
    background-color: #fbf6f2;
    color: #000000;
}

main {
    padding-top: 6rem;

    .logged-in & {
        padding-top: 13.5rem;

        @media (min-width: 23.4375rem) {
            padding-top: 11.25rem;
        }

        @include respond-above(md) {
            padding-top: 9.75rem;
        }
    }

    .logged-in[user-type='private-user'] & {
        padding-top: 15rem;

        @media (min-width: 28.75rem) {
            padding-top: 12.25rem;
        }

        @include respond-above(md) {
            padding-top: 11rem;
        }

        @include respond-above(lg) {
            padding-top: 9.375rem;
        }
    }

    .has-lock & {
        padding-top: 8rem;

        @include respond-above(sm) {
            padding-top: 6rem;
        }

        @include respond-above(lg) {
            padding-top: 13rem;
        }
    }

    .has-locks & {
        padding-top: 11rem;

        @media (min-width: 25rem) {
            padding-top: 9rem;
        }

        @include respond-above(sm) {
            padding-top: 9rem;
        }

        @include respond-above(md) {
            padding-top: 8rem;
        }

        @include respond-above(lg) {
            padding-top: 15rem;
        }
    }

    .logged-in.has-lock & {
        padding-top: 13rem;

        @include respond-above(md) {
            padding-top: 11rem;
        }

        @include respond-above(lg) {
            padding-top: 15.5rem;
        }
    }

    .logged-in.has-locks & {
        padding-top: 18rem;

        @media (min-width: 25rem) {
            padding-top: 15.5rem;
        }

        @include respond-above(sm) {
            padding-top: 15rem;
        }

        @include respond-above(md) {
            padding-top: 13rem;
        }

        @include respond-above(lg) {
            padding-top: 17.5rem;
        }
    }

    .hyphen {
        &-auto {
            hyphens: auto;
        }
        &-manual {
            hyphens: manual;
        }
        &-none {
            hyphens: none;
        }
    }
}

#__next {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;
}

svg {
    fill: currentColor;
}
