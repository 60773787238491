.submenuList {
    direction: rtl;

    &::-webkit-scrollbar {
        width: 0.375rem;
    }

    &::-webkit-scrollbar-track {
        background: var(--chakra-colors-grey-100);
        border-radius: 0rem;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--chakra-colors-grey-300);
        border-radius: 0rem;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--chakra-colors-grey-400);
    }

    li {
        direction: ltr;
    }
}
