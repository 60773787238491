.custom-scroll {
    --scroll-size: 0.625rem;

    &::-webkit-scrollbar {
        height: var(--scroll-size);
        width: var(--scroll-size);
    }

    &::-webkit-scrollbar-track {
        background: var(--chakra-colors-grey-100);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--chakra-colors-grey-300);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--chakra-colors-grey-400);
    }
}
