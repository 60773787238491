input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    border: 1px solid var(--chakra-colors-grey-500);
    -webkit-text-fill-color: var(--chakra-colors-grey-500);
    -webkit-box-shadow: 0 0 0px 1000px var(--chakra-colors-accent-200) inset;
    transition: background-color 5000s ease-in-out 0s;

    &:hover {
        border: 1px solid var(--chakra-colors-brand-main);
    }

    &:focus-visible {
        border: 1px solid var(--chakra-colors-brand-darkest);
    }
}
